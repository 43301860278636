.menu_outer_container {
  height: auto;
  box-sizing: border-box !important;
}

.with-progress {
  height: 100%;
}

.progress-div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
