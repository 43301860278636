.privacy-policy-section {
  height: calc(100vh - 80px);
  overflow: auto;
  scrollbar-width: none;
}

.privacy-policy-section::-webkit-scrollbar {
  display: none;
}

.privacy-policy-section .profileHeaderoption {
  border: none;
}

.privacy-policy-section .profileHeaderaccount {
  border: none;
}

.unorder-list {
  padding: 0px;
  margin: 0px;
}

.marker-span {
  vertical-align: text-top;
  margin-right: 2px;
}

.privacy-sub-para {
  margin-left: 5px;
}

.sub-header-marker {
  width: 5px;
  height: 5px;
  background-color: #000000;
  display: inline-block;
  border-radius: 50%;
}

.privacy-policy-section h1 {
  text-align: center;
  font: normal normal 600 20px/24px Titillium Web;
  letter-spacing: 0.4px;
  color: #1f2933;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.privacy-paragraph-title {
  /* text-align: left; */
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
  font: normal normal bold 13px/16px Titillium Web;
  margin-bottom: 10px;
  display: block;
}

.table-data {
  font: normal normal 600 12px/16px Titillium Web;
  letter-spacing: 0.4px;
  color: #1f2933;
  padding: 5px;
}

.privacy-policy-section table {
  margin-left: 5px;
}

.privacy-policy-section table tr,
.privacy-policy-section table td {
  border-width: 1px;
}

.table-heading {
  display: none;
}

.first-td {
  width: 60%;
}

.second-td {
  width: 40%;
}

.privacy-paragraphOne {
  width: 321px;
  /* text-align: left; */
  font: normal normal normal 12px/16px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  margin-left: 5px;
}

@media (max-width: 500px) {
  .privacy-paragraph-title,
  .privacy-paragraphOne,
  .privacy-paragraphTwo,
  .privacy-paragraph-title-two,
  .privacy-paragraphThree {
    width: 100%;
  }
}
