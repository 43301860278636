.order-screen {
  background-color: #ffffff;
  padding: 70px 40px;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
  position: relative;
  height: 100%;
}

#close-button {
  position: absolute;
  top: 2%;
}

.close-right {
  right: 80%;
}

.close-left {
  left: 80%;
}

#close-btn-tag {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  width: 42px;
  height: 42px;
  align-items: center;
  border-radius: 50%;
}

.orderheader-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 45px 20px 45px;
  font-size: 16px;
}

.update-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#text-for-arrival {
  letter-spacing: 0.5px;
  text-align: left;
  font: normal normal 600 16px/18px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
}

.ordercurrent-time {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  font: normal normal bold 20px/24px Titillium Web;
  letter-spacing: 0.4px;
  color: #1f2933;
  opacity: 1;
}

.order-progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderprogress-bar {
  width: 50px;
  height: 5px;
  background-color: #dcdfe3;
}

.orderaddress-section hr {
  height: 1px;
  background-color: #dcdfe3;
  border: none;
}

#order-status-tag {
  background-color: #f7b500;
  border-radius: 7px;
  letter-spacing: 0.4px;
  font-size: 15px;
  text-align: center;
  padding: 0px 10px;
  height: 24px;
  min-width: 96px;
  align-self: center;
}

.order-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 15px; */
}

#order-status-tag p {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
}

#orderconfirm-text {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #959fa8;
}

.orderconfirm-text-container {
  margin-top: 15px;
  width: 170px;
  display: flex;
  justify-content: end;
}

.orderaddress-text {
  font-size: 14px;
  line-height: 110%;
}

.order-id-section {
  display: flex;
  justify-content: space-between;
}

#order-id-text {
  font-size: 16px;
  text-align: left;
  font: normal normal 600 16px/18px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
}

.orderid-with-tag {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: -1px;
}

.orderid-with-tag p {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  font: normal normal bold 20px/24px Titillium Web;
  letter-spacing: 0.4px;
  color: #1f2933;
  opacity: 1;
}

#order-id-tag a {
  font-weight: bold;
  font-size: 12px;
  color: white;
  text-decoration: none;
}

#order-id-tag {
  background-color: #98694e;
  border-radius: 10px;
  width: 88px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

.ordercontact-section {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 50px 0 70px 0 !important;
}

#call-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b6482;
  height: 28px;
  width: 98px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  margin-left: -8px;
}

#call-tag a {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  margin-left: 6px;
}

#whatsapp-tag {
  display: flex;
  justify-content: space-around;
  padding: 0 3px;
  align-items: center;
  width: 150.32px;
  height: 27px;
  background-color: #2fa124;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
  margin-left: 8px;
}

#whatsapp-tag a {
  color: #ffffff;
  text-decoration: none;
}

#thanksnote {
  font: normal normal 400 16px/18px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1;
  position: absolute;
  top: 77px;
  width: 146px;
  height: 42px;
  white-space: pre-line;
}

.thanksnote-en {
  left: 150px;
  text-align: left;
}

.thanksnote-ar {
  right: 150px;
}

.orderformatted-address,
.orderformatted-address .location-img,
.address-details {
  /* text-align: left;
  letter-spacing: 0px;
  color: #1f2933;
  opacity: 1; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-line;
}

.address-details {
  font-size: 16px;
  color: #40484e;
}

.orderaddress-text p {
  text-align: left;
  font: normal normal 400 14px/16px Titillium Web;
  letter-spacing: 0px;
  color: #1f2933;
  margin-bottom: 0rem !important;
}
.orderconfirm-footer .footer-section {
  display: flex-end;
  bottom: 26px;
  right: 26px;
  position: fixed;
}

@media screen and (min-width: 600px) {
  .orderconfirm-footer .footer-section {
    position: absolute;
  }
}
