/* Montserrat */
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Titillium Web */
@font-face {
  font-family: "Titillium Web";
  src: url("./fonts/TitilliumWeb/TitilliumWeb-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./fonts/TitilliumWeb/TitilliumWeb-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./fonts/TitilliumWeb/TitilliumWeb-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Titillium Web";
  src: url("./fonts/TitilliumWeb/TitilliumWeb-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
