.detail {
  top: 290px;
  left: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  border-radius: 20px;
  overflow-x: hidden;
  padding-bottom: 50px;
  width: 100%;
}

.detail::-webkit-scrollbar {
  display: none;
}

.section {
  z-index: 1;
  margin-top: -15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: relative;
  padding-top: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 375px;
  padding-left: 15px;
  padding-right: 15px;
}

.not-delivered .dialog-title {
  border-bottom: none !important;
  padding-bottom: 0px !important;
  margin: 26px auto 0px !important;
}

.not-delivered .dialog-title {
  border-bottom: none !important;
  padding-bottom: 0px !important;
  margin: 26px auto 0px !important;
}

.content {
  padding: 16.5px 15px 24px !important;
}

.text-detail {
  font: normal normal bold 16px/18px Titillium Web;
  color: #959fa8;
  margin-bottom: 38px;
}

.disp-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.btn {
  border-radius: 12px;
  opacity: 1;
  width: 142px;
  line-height: 24px !important;
  padding: 3px 0px !important;
}

.btn span {
  font: normal normal 600 14px/24px Titillium Web;
}

.change-button {
  background: #ffffff 0% 0% no-repeat border-box;
  border: 1px solid #98694e !important;
}

.change-button span {
  color: #98694e;
}

.back-button {
  border: 1px solid #707070;
  background-color: #98694e !important;
}

.back-button span {
  color: #ffffff;
}

.extra-topping-price-wrapper {
  width: 100%;
  display: flex;
  position: relative;
}

.extra-topping-price {
  font-size: 10px;
  margin-left: 3px;
  position: absolute;
  top: -5px;
}

.price-box {
  position: relative;
}

.price-box .curr {
  width: 100%;
  text-align: end;
  position: absolute;
  left: 10px;
}

.arabic-quant h6 {
  margin-right: 8px !important;
}

.arabic-quant-top h6 {
  margin-right: 8px !important;
}

.detail-label {
  position: absolute;
  width: 75.5px;
  height: 23px;
  top: -7px;
  left: 20px;
  background-color: #00BF9B;
  border-radius: 0px 0px 5px 5px;
  /* Ensure the label is above the image */
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-triangle {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  position: absolute;
  top: -0.2px;
  right: -8px; /* Position the triangle on the right edge of the label */
}


@media screen and (max-width: 500px) {
  .section {
    width: 100%;
  }

  .text-detail {
    margin-bottom: 28px;
  }

  .content {
    padding: 16.5px 20px 24px !important;
  }
}
