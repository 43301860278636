.closeButtonTag {
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -10px;
}

.close-btn-ar {
  margin-left: 45px;
}

.close-btn-en {
  margin-right: 45px;
}

.tag-ar {
  margin-right: 8px;
  margin-left: 0px !important;
}

.tag-en {
  margin-left: 8px;
}

.call-tag-ar {
  margin-right: -8px;
  margin-left: 0px !important;
}

.call-tag-ar a {
  margin-right: 6px;
  margin-left: 0px !important;
}

.call-tag-en {
  margin-left: -8px;
}
