.skeleton-container {
  /* display: flex; */
  width: 327px;
  height: 160px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #314f7c1f;
  border: 1px solid #c0cdd7;
  border-radius: 12px;
  padding-top: 5px;
  margin-bottom: 15px;
}

.skeleton-header {
  display: flex;
}

.skeleton-icon {
  width: 19px;
  height: 17px;
  opacity: 1;
  margin-left: 14px;
  margin-top: 13px;
}

.skeleton-title {
  margin-left: 15px;
}

.skeleton-title-ar {
  margin-right: 15px;
  margin-left: 0px;
}

.skeleton-title2 {
  margin-left: 15px;
}

.skeleton-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 18px;
}

.skeleton-button-ar {
  margin-left: 18px;
  margin-right: 0px;
}

.skeleton-btn {
  margin-right: 10px;
}

.skeleton-btn-ar {
  margin-left: 10px;
  margin-right: 0px;
}

.skeleton-dots {
  margin: auto;
}

.skeleton-detail {
  display: flex;
  justify-content: space-between;
}

.skeleton-rectangular {
  margin-right: 18px;
  height: 100% !important;
  width: 80px;
  border-radius: 12px;
  margin-top: 10px;
}

.skeleton-rectangular-ar {
  margin-left: 18px;
  margin-right: 0px;
}

.delete-button {
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.delete-button:hover {
  background-color: rgb(106, 73, 54) !important;
}

/* .delete-button:hover span {
  color: #1f2933 !important;
} */

.loader-button .loader {
  width: 20px !important;
  height: 20px !important;
  color: #fff;
}

.loader-button {
  background-color: rgb(106, 73, 54) !important;
}

.parent-address {
  height: 75vh;
  overflow-y: auto;
  padding-top: 10px;
  width: 375px;
  margin: auto;
  scrollbar-width: none;
}

.parent-address::-webkit-scrollbar {
  display: none;
}

.no-address {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.location-icon {
  margin-bottom: 32px;
}

.empty-text {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #1f2933;
  width: 188px;
  display: block;
  text-align: center;
  font-family: Titillium Web !important;
}

@media only screen and (min-width: 1366px) and (max-width: 1366px) {
  .parent-address {
    height: 70vh;
  }
}

@media only screen and (max-width: 500px) {
  .delete-modal .MuiDialog-paper {
    width: 375px !important;
    margin: 30px !important;
    height: 190px !important;
  }

  .delete-modal .MuiDialog-paper .MuiDialogActions-root {
    justify-content: space-around;
    padding: 8px 24px;
    margin-right: 0px;
  }

  .delete-modal .MuiDialogContent-root {
    padding: 0px 24px;
  }

  .delete-para br {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .delete-modal .MuiDialog-paper {
    width: 90% !important;
    margin: 25px !important;
  }
}

@media screen and (max-width: 500px) and (min-width: 355px) {
  .parent-address {
    height: 72vh;
  }
}
