.house {
  top: 90px;
  left: 37px;
  width: 88px;
  height: 88px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 50px #74869426;
  border: 1px solid #ebeff3;
  border-radius: 12px;
  opacity: 1;
}

.active {
  top: 90px;
  left: 144px;
  width: 88px;
  height: 88px;
  background: #98694e 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 50px #74869426;
  border: 1px solid #ebeff3;
  border-radius: 12px;
  opacity: 1;
}

.button-text-active {
  top: 146px;
  left: 62px;
  width: 38px;
  height: 21px;
  text-align: center;
  font: normal normal 600 14px/21px Titillium Web;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.button-text-inactive {
  left: 156px;
  width: 64px;
  height: 21px;
  text-align: center;
  font: normal normal 600 14px/21px Titillium Web;
  letter-spacing: 0px;
  color: #40484e;
  opacity: 1;
}

.office {
  top: 90px;
  left: 37px;
  width: 88px;
  height: 88px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 50px #74869426;
  border: 1px solid #ebeff3;
  border-radius: 12px;
  opacity: 1;
}

.apartment {
  top: 90px;
  left: 37px;
  width: 88px;
  height: 88px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 50px #74869426;
  border: 1px solid #ebeff3;
  border-radius: 12px;
  opacity: 1;
}

.button-style {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.address-button {
  width: 100%;
  height: 56px;
  background: #98694e 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 20px;
  border-radius: 12px;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.address-button:hover {
  background-color: #bd8d72;
  color: #ecf0f1;
}

.address-text {
  width: 44px;
  height: 34px;
  text-align: center;
  font: normal normal 600 20px Titillium Web;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

.address-button:focus {
  outline: none;
}

.edit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 327px; */
  /* height: 64px; */
  background: #dedede 0% 0% no-repeat padding-box;
  border: 1px solid #c0cdd7;
  border-radius: 12px;
  opacity: 1;
  margin: 20px 17px 0px;
  padding: 10px 0px;
}

.edit-button {
  /* margin-left: 195px; */
  /* margin-top: 16px; */
  margin-right: -8px;
  width: 123px;
  height: 32px;
  background: #986a4f 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: none;
  /* top: -41px;
  position: relative; */
}

.edit-text {
  text-align: right;
  font: normal normal 600 16px/24px Titillium Web;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.address-name {
  width: 100px;
  /* height: 16px; */
  text-align: left;
  font: normal normal bold 16px/16px Titillium Web;
  letter-spacing: 0px;
  color: #40484e;
  opacity: 1;
  margin: 10px 0px 5px;
  white-space: nowrap;
}

.contact-style {
  text-align: center;
  padding-bottom: 15px;
  width: 100%;
  font: normal normal bold 20px/24px Titillium Web;
  letter-spacing: 0.4px;
  color: #1f2933;
  opacity: 1;
  margin-top: 1px;
}

.contact-section {
  padding-bottom: 20px;
}

.country-code-input {
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #c0cdd7;
  border-radius: 12px;
  opacity: 1;
  width: 78px;
  height: 64px;
  margin-right: 10px;
  /* padding-left: 19px; */
  font: normal normal 16px/28px Titillium Web;
  color: #000000;
}

.text-right::placeholder {
  text-align: right !important;
}

.phone-number-input {
  width: 230px;
  height: 64px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #c0cdd7;
  border-radius: 12px;
  opacity: 1;
  font: normal normal 16px/28px Titillium Web;
  /* padding-left: 19px; */
  color: #000000;
}

.country-code-input:focus {
  outline: none;
  /* color: #959fa8; */
}

.phone-number-input:focus {
  outline: none;
  /* color: #959fa8; */
}

.error {
  color: #e02020 !important;
  border: 1px solid #e02020 !important;
}

.error:focus {
  border: 1px solid #e02020 !important;
  color: #e02020 !important;
}

.error::placeholder {
  color: #e02020 !important;
}

.phone-number-input::placeholder {
  text-align: left;
  font: normal normal bold 16px/28px Titillium Web;
  letter-spacing: 0px;
  color: #959fa8;
  opacity: 1;
}

.country-code-input::placeholder {
  font: normal normal bold 16px/28px Titillium Web;
  letter-spacing: 0px;
  color: #959fa8;
  opacity: 1;
}

.phone-number-input::-webkit-outer-spin-button,
.phone-number-input::-webkit-inner-spin-button,
.country-code-input::-webkit-outer-spin-button,
.country-code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disabled-input {
  background-color: #dedede;
}

/* @media screen and (max-width: 500px) and (min-width: 380px){
  .edit-container{
    margin-left: 30px;
  }
  .address-button{
    margin-left: 30px;
  }
} */
