.pickup-section {
  height: calc(100vh - 80px);
  overflow: auto;
  scrollbar-width: none;
  padding: 20px;
}

.nearme-text {
  font: normal normal 600 14px Titillium Web !important;
  color: #1f2933;
  margin-top: 25px;
  display: block;
  text-align: center;
  margin-bottom: 16px;
}

.branch-footer-ar {
  margin-right: 15px;
}

.branch-footer-en {
  margin-left: 15px;
}

@media screen and (max-width: 500px) {
  .branch-footer-ar,
  .branch-footer-en {
    width: 92%;
  }
}

.branches-screen-container {
  margin-top: 13px;
  /* position: relative; */
  height: calc(100% - 13px);
}

.api-calling {
  height: calc(100% - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  width: 50px !important;
  height: 50px !important;
}
