.receipt-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 25px 15px;
}

.receipt-head {
  color: #1f2933;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin-bottom: 1rem;
}

.receipt-head p {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 0rem !important;
}

.receipt-info {
  padding: 20px 40px;
  background-color: #fafafa;
  border-radius: 25px;
  margin: 0 25px;
}

.receipt-info div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#location-img,
#clock-img {
  width: 13px;
  height: 13px;
}

.left-margin {
  margin-left: 20px;
}

.right-margin {
  margin-right: 20px;
}

#heading {
  font-size: 20px;
  font-weight: bold;
  color: #40484e;
  padding: 10px 0;
}

#heading-cont {
  font-size: 16px;
  color: #40484e;
}

.receipt-items {
  color: #1f2933;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 30px 0;
}

.receipt-items img {
  width: 72px;
  height: 72px;
}

.receipt-items-name {
  width: 100%;
}

.reciept-item-padding-right {
  padding-right: 12px;
}

.reciept-item-padding-left {
  padding-left: 12px;
}

.receipt-items-name span {
  font-size: 14px;
}

.items-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items-div .first-span {
  width: 150px;
}

.receipt-items-name .second-span {
  text-align: end;
}

.receipt-items-price {
  width: 87px;
  font-weight: 400;
  font-size: 14px;
  text-align: end;
  display: block;
}

.receipt-comment-section {
  margin-left: 80%;
}

/* @media screen and (max-width: 450px) and (min-width: 400px) {
  .receipt-items-name {
    margin-left: -62px;
  }
} */
